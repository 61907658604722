/* Miscellaneous */
.btn {
    min-width: 155px;
    background: $secondary;
    color: #fff;
    font-weight: bold;
    font-size: rem(14px);
    letter-spacing: .4px;
    text-align: center;
    text-transform: uppercase;
    transition: background .2s;

    &:hover {
        background: $primary;
    }
}

.image_container {
    .caption {
        margin-top: rem(27px);
        padding-bottom: 6px;
        border-bottom: 1px solid $secondary;
        color: #59595a;
        font-size: rem(13px);
        font-style: italic;
    }

    img {
        width: 100%;
    }
}

.lead-text {
    font-family: $font-family-alt;
    font-size: rem(20px);
    font-weight: 400;

    @include media-breakpoint-up(lg) {
        text-align: center;
    }
}

// External links
.ce_text a[target="_blank"]:before {
    margin-right: 7px;
    font-size: 10px;
    content: $icon-external;

    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Eliminates margin caused by news sections module (leave 2px gap)
.sections-negative-top {
    margin-top: rem(-8px);

    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
}

@include media-breakpoint-down(lg) {
    .container {
        padding-left: 10px;
        padding-right: 10px;
    }
}

// Make the videos responsive
.video_container {
    video, audio {
        width: 100%;
        height: auto;
    }
}

.responsive {
    position: relative;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
    &.ratio-169 {
        padding-bottom: 56.25%;
    }
    &.ratio-1610 {
        padding-bottom: 62.5%;
    }
    &.ratio-219 {
        padding-bottom: 42.8571%;
    }
    &.ratio-43 {
        padding-bottom: 75%;
    }
    &.ratio-32 {
        padding-bottom: 66.6666%;
    }
}
