/* Helper class */

/* Reset style */
%reset {
    margin: 0;
    padding: 0;
    list-style: none;
    background: transparent;
    border: none;
}

.reset {
    @extend %reset;
}
