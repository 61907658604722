body.event-reader {
    .header__back {
        visibility: visible;
        pointer-events: auto;
    }

    @include media-breakpoint-down(md) {
        .footer__content {
            padding-bottom: 68px; // Make space for actions bar
        }
    }
}

.mod_event_reader {
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
        padding-top: 8px;
    }
}

.event-reader-comments {
    margin-top: rem(40px);
    padding: rem(40px) 0;
    background: #eaeff4;
    border-top: 3px solid #043260;
}

$this: event-reader-item;

.#{$this} {
    &__section {
        margin-bottom: 5px;
        font-family: $font-family-alt;
        font-size: rem(11px);
        font-weight: 800;
        text-transform: uppercase;
        color: #159265;

        @include media-breakpoint-up(lg) {
            margin-bottom: rem(15px);
            text-align: center;
            font-size: rem(16px);
            font-weight: 700;
        }
    }

    &__headline {
        margin-top: 0;
        margin-bottom: rem(8px);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem(28px);
            text-align: center;
        }
    }

    &__subheadline {
        margin-bottom: rem(10px);
        font-family: $font-family-alt;
        font-size: rem(20px);
        font-weight: 400;

        @include media-breakpoint-up(lg) {
            margin-bottom: rem(25px);
            text-align: center;
        }
    }

    &__image {
        margin-bottom: rem(17px);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem(30px);
        }

        &-container {
            @extend .container;

            @include media-breakpoint-down(md) {
                max-width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }

        &-caption {
            margin-top: 5px;
            color: #59595a;
            font-size: rem(13px);
            font-style: italic;
        }

        img {
            width: 100%;
        }
    }

    &__meta {
        max-width: 300px;
        margin: 0 auto rem(27px) auto;
        padding: rem(20px);
        position: relative;
        font-size: rem(14px);
        background: #f0efef;

        @include media-breakpoint-up(lg) {
            margin-bottom: rem(50px);
        }

        &:after {
            content: '';
            position: absolute;
            top: -14px;
            right: 17px;
            display: block;
            width: 29px;
            height: 51px;
            background: url('../../../images/event-pin.svg') no-repeat top center;
            background-size: contain;
            pointer-events: none;
        }

        p {
            margin-bottom: 0;
        }

        &-headline {
            position: relative;
            padding-bottom: 8px;
            font-size: rem(22px);
            color: #525252;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                display: block;
                width: 41px;
                height: 4px;
                background: #159265;
            }
        }

        &-item:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}
