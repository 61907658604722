$this: event-author;

.#{$this} {
    display: flex;
    align-items: center;

    &--list {
        justify-content: flex-end;
        color: #fff;

        a {
            color: #fff;
        }

        .#{$this}__content {
            font-size: .725rem;
            line-height: 1.1;
        }

        .#{$this}__image {
            margin-right: 7px;
            width: 27px;
        }

        .#{$this}__author {
            @include media-breakpoint-up(lg) {
                color: #fff;
            }
        }
    }

    &--reader {
        margin-bottom: rem(20px);

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            text-align: center;
            flex-direction: column;
        }

        .#{$this}__content {
            font-size: rem(13px);
            line-height: 1.23;
        }

        .#{$this}__image {
            margin-right: 8px;
            width: 50px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 8px;
                margin-right: 0;
                width: 90px;
            }
        }
    }

    &__content {
        font-family: $font-family-alt;
    }

    &__author {
        font-weight: 700;
        color: #000000;

        > a {
            color: $link-color;

            &:hover {
                color: darken($link-color, 20%);
            }
        }
    }

    &__image {
        margin: 0;

        img {
            border-radius: 50%;
        }
    }
}
