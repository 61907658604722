/* Variables */
$font-size: 16px;
$font-size-base: 1rem;
$line-height-base: 22 / 16;
$font-family-base: Lato, sans-serif;
$font-weight-base: 300;
$font-family-alt: Muli, sans-serif;
$font-family-weight: 400;
$body-color: #121213;
$primary: #05396b;
$secondary: #1dc688;

/* Links */
$link-color: $secondary;
$link-hover-decoration: none;

/* Buttons */
$btn-border-radius: 0;
$btn-border-width: 0;
$btn-padding-y: rem(8.5px);

/* Input fields */
$input-border-radius: 2px;
$input-border-color: #dddde1;

/* Headings */
$h1-font-size: rem(24px);
$h2-font-size: rem(19px);
$h3-font-size: rem(18px);
$h4-font-size: rem(16px);
$h5-font-size: rem(14px);
$h6-font-size: rem(13px);

/* Grid & breakpoints */
$grid-gutter-width: 20px;

/* Pagination */
$pagination-active-bg: $secondary;
$pagination-active-border-color: $secondary;

/* IcoMoon fonts */
$icomoon-font-path: '../icons/fonts';

/* Vertical rhythm */
$vr: $line-height-base * 2rem;
