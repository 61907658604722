body {
    padding-top: calc(50px + #{rem(20px)});

    @include media-breakpoint-up(lg) {
        padding-top: calc(40px + #{rem(36px)});
    }
}

.section-header {
    padding-top: 10px;

    @include media-breakpoint-up(lg) {
        padding-top: 0;
    }

    .ad_zone > * {
        margin-bottom: 20px !important;
    }

    &__bar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        height: 50px;
        align-items: center;
        background: $primary;

        @include media-breakpoint-up(lg) {
            display: flex;
            padding: 0 30px;
            height: 55px;
        }

        @include media-breakpoint-down(md) {
            > * {
                display: none !important;
            }
        }
    }

    &__logo {
        display: block !important;
        position: fixed;
        left: calc(50% - 79px);
        top: 14px;
        z-index: 11;
        width: 158px;

        @include media-breakpoint-up(lg) {
            width: 211px;
            top: 12px;
            left: calc(50% - 105px);
        }
    }

    &__back {
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        position: fixed;
        left: 15px;
        top: 11px;
        z-index: 11;
        color: #fff;
        visibility: hidden;
        pointer-events: none;
    }

    &__social {
        margin-right: auto;
        display: flex;

        &-link {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            color: #829cb5;
            font-size: 12px;
            border: 2px solid #829cb5;
            border-radius: 50%;
            transition: all .2s;

            &:not(:last-child) {
                margin-right: 26px;
            }

            &:hover {
                color: $secondary;
                border-color: $secondary;
            }
        }
    }

    &__search {
        &-trigger {
            width: 34px;
            color: #fff;
            font-size: 24px;
            text-align: center;
            cursor: pointer;
        }

        &-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 15;
            background: rgba(#fff, .9);
            pointer-events: none;
            opacity: 0;
            transition: opacity .2s;

            body.search-active & {
                opacity: 1;
                pointer-events: auto;
            }
        }

        &-form {
            display: flex;
            align-items: stretch;
            position: absolute;
            right: 100px;
            top: 11px;
            width: 375px;
            border-bottom: 2px solid $body-color;
        }

        &-input[type="text"] {
            padding: 0 5px;
            background: none;
            border: none;
            border-radius: 0;
            font-family: $font-family-base;
            font-size: 16px;
            font-weight: 300;

            &:focus {
                background: none;
                box-shadow: none;
            }

            &::-webkit-input-placeholder {
                color: $body-color;
                opacity: 1;
            }
            &::-moz-placeholder {
                color: $body-color;
                opacity: 1;
            }
            &:-ms-input-placeholder {
                color: $body-color;
                opacity: 1;
            }
            &:-moz-placeholder {
                color: $body-color;
                opacity: 1;
            }
        }

        &-button {
            margin: 0;
            padding: 0 6px;
            font-size: 24px;
            background: none;
            border: none;
            border-radius: 0;
            cursor: pointer;
        }
    }

    &__menu-trigger {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin-left: 39px;
        width: 31px;
        height: 18px;
        cursor: pointer;

        span {
            background-color: #ffffff;
            display: block;
            width: 100%;
            height: 2px;
            transition: all .2s ease-in-out;
        }

        body.menu-active & span {
            &:nth-child(1) {
                transform: translateY(8px) rotate(135deg);
            }
            &:nth-child(2) {
                transform: scale(0);
            }
            &:nth-child(3) {
                transform: translateY(-8px) rotate(-135deg);
            }
        }
    }

    .main-navigation {
        display: none;
        margin: 0;

        @include media-breakpoint-up(lg) {
            display: block;

            ul {
                display: flex;
                justify-content: center;
                margin: 0 0 20px;
                padding: 0;
                list-style-type: none;
            }

            li {
                margin: 0;
                padding: 0;
            }

            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px;
                font-size: 20px;
                font-weight: normal;
                color: #989898;
                line-height: 1;

                &:hover,
                &.active,
                &.trail {
                    color: #159265;
                }

                &:before {
                    display: block;
                    font-family: $icomoon-font-family;
                    font-size: 36px;
                    margin-bottom: 6px;
                }

                &.menu-news:before {
                    content: $icon-news;
                }

                &.menu-stores:before {
                    content: $icon-stores;
                }

                &.menu-agenda:before {
                    content: $icon-agenda;
                }

                &.menu-municipals:before {
                    content: $icon-municipals;
                }
            }
        }
    }
}
