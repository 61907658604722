.news-list {
    @include item-listing;
}

$this: news-list-item;

@mixin news-list-item-featured() {
    padding: 10px;
    background: $primary;

    .#{$this}__author {
        display: none;
    }

    .#{$this}__section {
        margin-bottom: 5px;
        padding: 1px 7px;
        background: #fff;

        @include media-breakpoint-up(lg) {
            margin-bottom: rem(10px);
        }
    }

    .#{$this}__headline {
        max-width: 90%;
        padding-left: 7px;
        line-height: rem(23px);

        @include media-breakpoint-up(lg) {
            font-size: rem(30px);
            line-height: rem(38px);
        }

        &-text {
            color: #fff;
            background: $secondary;

            // @see https://css-tricks.com/multi-line-padded-text/#article-header-id-1
            box-shadow: 17px 0 0 $secondary, -7px 0 0 $secondary;
            box-decoration-break: clone;
        }
    }

    .#{$this}__main {
        margin-bottom: 14px;

        &:hover .#{$this}__headline-text {
            color: #fff;
        }
    }

    .#{$this}__content {
        width: 100% !important;
    }

    .#{$this}__contents {
        position: absolute;
        left: 10px;
        top: 10px;
        color: #fff;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: -10px;
            top: -10px;
            width: 250px;
            height: 250px;
            z-index: -1;
            opacity: .75;

            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+50 */
            background: -moz-linear-gradient(-45deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(-45deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(135deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }
    }

    .item-actions {
        margin-left: 7px;
    }

    .item-actions a,
    .news-meta,
    .news-meta a,
    .news-meta__author {
        color: #fff;
    }

    &-image {
        position: relative;
        padding: 0;

        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 50%;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 0;
            pointer-events: none;

            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0); /* IE6-9 */
        }

        .#{$this}__container {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 10px;
            z-index: 1;
            pointer-events: none;

            > * {
                pointer-events: auto;
            }
        }

        .#{$this}__image {
            margin: 0;
            display: block;
        }

        .#{$this}__thumbnail {
            display: none;
        }
    }
}

.#{$this} {
    margin-bottom: rem(12px);

    @include media-breakpoint-up(lg) {
        margin-bottom: rem(28px);
    }

    &--featured {
        @include news-list-item-featured;
    }

    &--featured-mobile {
        @include media-breakpoint-down(md) {
            @include news-list-item-featured;
        }
    }

    &--small {
        @include media-breakpoint-up(lg) {
            margin-bottom: 18px;
        }
    }

    &--related {
        @include media-breakpoint-up(lg) {
            .#{$this}__image {
                display: none !important;
            }

            .#{$this}__info {
                flex-direction: column;
                align-items: flex-start;

                .news-meta {
                    margin-bottom: 9px;
                    order: 0;
                }

                .item-actions {
                    order: 1;
                }
            }
        }
    }

    &:not(&--small) {
        @include media-breakpoint-up(lg) {
            .#{$this}__content {
                width: 100%;
            }

            .#{$this}__image {
                display: block;
            }

            .#{$this}__thumbnail {
                display: none;
            }
        }
    }

    &--section {
        .#{$this}__section {
            display: inline-block;
        }

        .#{$this}__contents--opinion {
            display: none;
        }
    }

    &__main {
        display: block;
        margin-bottom: 9px;
        color: $body-color;
        pointer-events: auto;

        &:hover .#{$this}__headline-text {
            color: $secondary;
        }

        &--image {
            display: flex;

            .#{$this}__content {
                width: 62%;
            }
        }
    }

    &__section {
        display: none;
        margin-bottom: 1px;
        font-family: $font-family-alt;
        font-size: rem(11px);
        font-weight: 800;
        letter-spacing: .03rem;
        text-transform: uppercase;

        &-text {
            color: #159265;
        }

        &-subject {
            color: #a9a9a9;

            i {
                margin: 0 5px;
                font-style: normal;
            }
        }
    }

    &__headline {
        margin-bottom: 0;
        font-weight: 600;

        @include media-breakpoint-up(lg) {
            margin-bottom: 3px;
        }

        &--opinion {
            font-style: italic;
        }

        &-text {
            color: $body-color;
        }
    }

    &__contents {
        @extend %reset;
        display: flex;
        flex-wrap: wrap;
        color: #898888;
        font-size: rem(12px);

        &-item {
            @extend %reset;

            &:not(:last-child):after {
                content: '|';
                margin: 0 10px;
            }
        }
    }

    &__image {
        display: none;
        margin-bottom: 10px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 12px;
        }

        &-link {
            display: block;
        }

        img {
            width: 100%;
        }
    }

    &__author,
    &__thumbnail {
        width: 40%;
        margin: 9px 0 0 0;
        padding-left: 20px;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
