.section-menu {
    position: fixed;
    top: 50px; // .header height
    bottom: 50px; // .footer__mobile height
    left: 0;
    width: 100%;
    overflow: auto;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s;

    @include media-breakpoint-up(lg) {
        top: 55px; // .header__bar height
        bottom: 0;
        left: auto;
        right: 0;
        width: auto;
        max-width: 350px;
        min-height: auto;
        box-shadow: 0 0 12.09px 0.91px rgba(0, 0, 0, .3);
    }
}

body.menu-active .section-menu {
    opacity: 1;
    pointer-events: auto;
}

.menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100%;
    padding: 27px 15px 17px 15px;
    background: #fff;
    font-family: $font-family-alt;

    a {
        color: $body-color;
    }

    &__search {
        margin-bottom: rem(53px);
        padding: 0 20px;

        &-form {
            display: flex;
            align-items: stretch;
            border-bottom: 2px solid $body-color;
        }

        &-input[type="text"] {
            padding: 0 5px;
            background: none;
            border: none;
            border-radius: 0;
            font-family: $font-family-base;
            font-size: rem(16px);
            font-weight: 300;

            &:focus {
                background: none;
                box-shadow: none;
            }

            &::-webkit-input-placeholder {
                color: #bfbfbf;
                opacity: 1;
            }
            &::-moz-placeholder {
                color: #bfbfbf;
                opacity: 1;
            }
            &:-ms-input-placeholder {
                color: #bfbfbf;
                opacity: 1;
            }
            &:-moz-placeholder {
                color: #bfbfbf;
                opacity: 1;
            }
        }

        &-button {
            margin: 0;
            padding: 0 6px;
            font-size: 24px;
            background: none;
            border: none;
            border-radius: 0;
            cursor: pointer;
        }
    }

    &__navigation {
        @include media-breakpoint-up(lg) {
            margin-bottom: 2rem;
        }

        *[class*="mod_"] {
            margin: 0;
        }

        ul {
            @extend %reset;
        }

        li {
            position: relative;
            margin: 0;
            padding: 0;
        }

        a,
        strong {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 300;

            &.submenu {
                @include icon(after, unicode($icon-arrow-down), 7px);

                &:after {
                    font-size: 7px;
                }

                // Hide the subnavigation by default
                + * {
                    display: none;
                }

                // Show the subnavigation on expand
                &.submenu-expand {
                    &:after {
                        content: unicode($icon-arrow-up);
                    }

                    + * {
                        display: block;
                    }
                }
            }
        }

        a:hover,
        strong {
            color: $link-color;
        }

        .level_1 > li {
            margin-bottom: rem(17px);
            font-size: rem(24px);

            &:first-child,
            &:not(:last-child) {
                padding-bottom: rem(22px);
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    display: block;
                    width: 41px;
                    height: 2px;
                    background: #49d1a0;
                }
            }

            &.submenu > a.submenu:hover {
                color: $body-color;
            }
        }

        .level_2 > li {
            margin-bottom: rem(18px);
            font-size: rem(16px);

            &:first-child {
                margin-top: rem(15px);
            }
        }
    }

    &__social {
        justify-content: center;
        margin-top: auto;
        margin-bottom: rem(24px);
        padding-top: rem(24px);

        &-link {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 14px;
            width: 30px;
            height: 30px;
            color: $body-color;
            font-size: 12px;
            border: 2px solid $body-color;
            border-radius: 50%;
        }
    }

    &__links {
        display: flex;
        justify-content: center;
        font-size: rem(12px);

        *[class*="mod_"] {
            margin: 0;
        }

        ul {
            @extend %reset;
            display: flex;

            @include media-breakpoint-up(lg) {
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        li {
            position: relative;
            margin: 0;
            padding: 0;

            &.menu-navigation-mobile-hide {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }

            &:not(:last-child) {
                margin-right: 13px;
                padding-right: 15px;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 4px;
                    right: 0;
                    width: 1px;
                    height: 10px;
                    background: #bfbfbf;
                }
            }
        }

        a:hover {
            text-decoration: underline;
        }
    }
}
