.footer {
    padding-bottom: 50px; // .footer__mobile height

    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 10px;
        background: #525252;
        color: #fff;
        font-family: $font-family-alt;
        font-size: rem(12px);

        a {
            color: #fff;
        }
    }

    &__text {
        margin-bottom: rem(20px);
        font-weight: 700;
    }

    &__navigation {
        margin-bottom: 6px;

        *[class*="mod_"] {
            margin: 0;
        }

        ul {
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {
            position: relative;
            margin: 0;
            padding: 0;

            &.footer-navigation-mobile-hide {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }

            &:not(:last-child) {
                margin-right: 13px;
                padding-right: 15px;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 3px;
                    right: 0;
                    width: 2px;
                    height: 10px;
                    background: #a2a2a2;
                }
            }
        }

        a:hover {
            text-decoration: underline;
        }
    }

    &__copyright {
        color: #a2a2a2;
        font-size: rem(10px);
    }

    &__mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        z-index: 10;
        display: flex;
        align-items: center;
        padding: 0 30px;
        background: #fff;
        border-top: 1px solid #bfbfbf;

        .main-navigation {
            margin: 0;

            ul {
                display: flex;
                margin: 0;
                padding: 0;
                list-style-type: none;
            }

            li {
                margin: 0;
                padding: 0;
            }

            a {
                display: inline-block;
                margin-right: 53px;
                color: #269a70;
                font-size: 28px;
                font-weight: normal;
                line-height: 1;

                > span {
                    display: none;
                }

                &:before {
                    display: block;
                    font-family: $icomoon-font-family;
                }

                &.menu-news:before {
                    content: $icon-news;
                }

                &.menu-stores:before {
                    content: $icon-stores;
                }

                &.menu-agenda:before {
                    content: $icon-agenda;
                }

                &.menu-municipals:before {
                    content: $icon-municipals;
                }
            }
        }

        &-menu-trigger {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            height: 18px;
            width: 23px;
            cursor: pointer;

            span {
                background-color: #269a70;
                display: block;
                width: 100%;
                height: 2px;
                transition: all .2s ease-in-out;
            }

            body.menu-active & span {
                &:nth-child(1) {
                    transform: translateY(8px) rotate(135deg);
                }
                &:nth-child(2) {
                    transform: scale(0);
                }
                &:nth-child(3) {
                    transform: translateY(-8px) rotate(-135deg);
                }
            }
        }
    }
}
