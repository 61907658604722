$this: 'survey';

.#{$this} {
    max-width: 300px;
    margin: 0 auto rem(27px) auto;
    padding: rem(20px);
    position: relative;
    font-size: .97rem;
    background: #f0efef;
    color: #525252;

    @include media-breakpoint-up(lg) {
        max-width: 500px;
    }

    &__subheadline {
        margin-bottom: rem(15px);
        font-family: $font-family-alt;
        font-size: rem(11px);
        font-weight: 800;
        letter-spacing: .03rem;
        text-transform: uppercase;
        color: #159265;
    }

    &__headline {
        position: relative;
        margin-bottom: rem(12px);
        padding-bottom: 8px;
        font-size: rem(22px);
        color: #525252;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            display: block;
            width: 41px;
            height: 4px;
            background: #159265;
        }
    }

    &__question {
        margin-bottom: 0;
        font-size: rem(18px);
        font-weight: 300;
    }

    &__form {
        transition: opacity .2s;

        &.ajax-loading {
            opacity: .5;
        }
    }

    &__options {
        @extend %reset;
        margin-top: rem(17px);
        margin-bottom: rem(30px);
    }

    &__option {
        @extend %reset;

        &:not(:last-child) {
            margin-bottom: rem(10px);
        }

        input {
            display: none;
        }

        input:checked + .#{$this}__label {
            background: #fff;
            border-color: #fff;
        }
    }

    &__label {
        display: flex;
        margin-bottom: 0;
        padding: 7px 10px;
        border: 1px solid #444;
        transition: all .2s;
        cursor: pointer;

        &-counter {
            margin-right: 5px;
        }
    }

    &__submit-wrap {
        display: flex;
        justify-content: center;
    }

    &__results {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: rem(7px);

        &-chart {
            position: relative;
            margin-bottom: rem(25px);

            canvas {
                width: 100% !important;
                height: 100% !important;
            }
        }

        &-list {
            @extend %reset;
        }

        &-item {
            @extend %reset;
            display: flex;

            &:first-child {
                position: absolute;
                top: 0;
                left: 0;
                height: 230px;
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .#{$this}__results-item-value {
                    width: auto;
                    max-width: 200px;
                    margin: rem(35px) 0 0 0;
                    font-family: $font-family-alt;
                    font-size: rem(60px);
                }

                .#{$this}__results-item-label {
                    max-width: 200px;
                    text-align: center;
                }
            }

            &-value {
                width: 30px;
                margin: 0 15px 5px 0;
                font-weight: 700;
                white-space: nowrap;
                text-align: right;

                i {
                    margin-left: 5px;
                    font-style: normal;
                }
            }
        }
    }
}
