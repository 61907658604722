/* Mixins */

/* Vertical align */
@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin vertical-align-parent() {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

/* Sticky footer */
@mixin sticky-footer() {
    html {
        position: relative;
        min-height: 100%;
    }

    body {
        display: flex;
        flex-direction: column;
    }

    #wrapper {
        display: flex;
        min-height: 100vh;
        flex-direction: column;
    }

    #container {
        flex: 1 1 auto;
    }
}

/* Link variant */
@mixin link-variant($color: inherit, $hovercolor: null,  $textdecoration: null) {
    color: $color;

    &:focus,
    &:visited {
        color: $color;
    }

    @if ($hovercolor) {
        &:hover {
            color: $hovercolor;
        }
    } @else {

        @if ($color == inherit) {
            &:hover {
                opacity: .8;
            }
        } @else {
            &:hover {
                color: darken($color, 20%);
            }
        }
    }

    @if ($textdecoration) {
        &:hover,
        &:focus,
        &:active {
            text-decoration: $textdecoration;
        }
    } @else {
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }
}

/* Add icon */
@mixin icon($position: before, $icon: '', $margin: null, $color: null) {
    &:#{$position} {
        @if $icon {
            content: $icon;
            font-family: 'icons' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        @if $margin {
            @if ($position == before) {
                margin-right: $margin;
            }
            @if ($position == after) {
                margin-left: $margin;
            }
        }

        @if $color {
            color: $color;
        }
    }
}

/* Px to rem */
@function rem($size, $base: $font-size) {
    @return $size / $base + rem;
}

/* Item listing */
@mixin item-listing() {
    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__headline {
        position: relative;
        margin-bottom: rem(20px);
        padding-bottom: 11px;
        font-size: rem(30px);
        font-weight: 600;
        line-height: 1;
        color: #525252;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            display: block;
            width: 41px;
            height: 4px;
            background: #159265;
        }
    }

    &__more {
        margin-top: rem(9px);
        font-family: $font-family-alt;
        font-size: rem(16px);
        font-weight: 700;
        white-space: nowrap;

        @include media-breakpoint-up(lg) {
            margin-top: 0;
            font-size: rem(24px);
            font-weight: 400;
        }

        &:after {
            content: " >";
        }
    }

    &__empty {
        font-family: $font-family-alt;
        font-style: italic;
        font-weight: 400;

        @include media-breakpoint-up(lg) {
            margin-bottom: 4rem;
        }
    }
}
