/* Base */
/* Vertical rhythm */
h1, h2, h3, h4, h5, h6,
ul, ol, dd,
p, figure,
pre, table, fieldset, hr,
*[class^="ce_"]:not(.ce_headline), *[class^="mod_"] {
    margin-top: 0;
    margin-bottom: $vr;
}

.mod_article {
    margin-bottom: 0;
}

#header *[class^="mod_"],
#footer *[class^="mod_"] {
    margin-bottom: 0;
}

/* Grid */
.mod_article .row {
    > *[class^="ce_"],
    > *[class^="mod_"] {
        padding-left: ($grid-gutter-width / 2);
        padding-right: ($grid-gutter-width / 2);
    }

    .ce_column {
        margin-bottom: 0;

        > *[class^="ce_"],
        > *[class^="mod_"] {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

/* Float classes */
.float_left {
    float: left;

    @include media-breakpoint-down(sm) {
        float: none;
    }

    &.image_container {
        margin-bottom: $vr;
        margin-right: $vr;

        @include media-breakpoint-down(sm) {
            margin-right: 0;
        }
    }
}

.float_right {
    float: right;

    @include media-breakpoint-down(sm) {
        float: none;
    }

    &.image_container {
        margin-bottom: $vr;
        margin-left: $vr;

        @include media-breakpoint-down(sm) {
            margin-left: 0;
        }
    }
}

/* Clear floats */
.clear,
#clear {
    height: 0.1px;
    font-size: 0.1px;
    line-height: 0.1px;
    clear: both;
}

/* Hide invisible elements */
.invisible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

/* Custom layout sections */
.custom {
    display: block;
}

#container:after,
.custom:after {
    @include clearfix;
}

/* Fix the Contao .block */
.block {
    overflow: visible;
}
