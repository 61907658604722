.event-list-home {
    padding-top: 30px;
    border-top: 40px solid #e7e7e7;
}

.event-list {
    @include item-listing;
}

$this: event-list-item;

.#{$this} {
    margin-bottom: rem(10px);
    background: $primary;
    padding: 10px;

    @include media-breakpoint-up(lg) {
        margin-bottom: rem(26px);
    }

    &--image {
        position: relative;
        padding: 0;

        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 50%;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 0;
            pointer-events: none;

            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0); /* IE6-9 */

            @include media-breakpoint-up(lg) {
                top: 0;
                bottom: auto;

                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100 */
                background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0); /* IE6-9 */
            }
        }

        .#{$this}__container {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 10px;
            z-index: 1;
            pointer-events: none;

            @include media-breakpoint-up(lg) {
                padding-bottom: 20px;
            }
        }

        .#{$this}__image {
            margin: 0;
        }
    }

    &__main {
        display: block;
        height: 100%;
        pointer-events: auto;
    }

    &__content {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;

        @include media-breakpoint-up(lg) {
            align-items: flex-start;
        }
    }

    &__image {
        &-link {
            display: block;
        }

        img {
            width: 100%;
        }
    }

    &__section {
        margin-bottom: 5px;
        padding: 1px 7px;
        display: inline-block;
        font-family: $font-family-alt;
        font-size: rem(11px);
        font-weight: 800;
        text-transform: uppercase;
        background: #fff;
        color: #159265;

        @include media-breakpoint-up(lg) {
            order: 1;
        }
    }

    &__headline {
        margin-bottom: rem(10px);
        padding-left: 7px;
        max-width: 90%;
        line-height: 1;
        font-weight: 600;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            order: 2;
        }

        &-text {
            color: #fff;
            background: $secondary;
            font-size: rem(18px);
            line-height: rem(23px);

            // @see https://css-tricks.com/multi-line-padded-text/#article-header-id-1
            box-shadow: 17px 0 0 $secondary, -7px 0 0 $secondary;
            box-decoration-break: clone;
        }

        &-meta {
            position: relative;
            top: -1px;
            font-size: rem(14px);
            font-weight: 300;
            color: #fff;
            background: $secondary;

            // @see https://css-tricks.com/multi-line-padded-text/#article-header-id-1
            box-shadow: 17px 0 0 $secondary, -7px 0 0 $secondary;
            box-decoration-break: clone;
        }
    }

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;

        @include media-breakpoint-up(lg) {
            margin-left: 0;
            margin-bottom: auto;
            order: 0;
        }
    }
}
