/* Mixins */
@import 'base/mixins';

/* Variables */
@import 'variables';

/* Vendor – Bootstrap */
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/pagination";

/* Vendor – IcoMoon fonts */
@import '../icons/variables';
@import '../icons/style.scss';

/* Base */
@import 'base/helper-class';
@import 'base/normalize';
@import 'base/scaffolding';
@import 'base/type';
@import 'base/form';

/* Layout */
@import 'layout/header';
@import 'layout/footer';
@import 'layout/menu';
@import 'layout/subnavi';

/* Content */
@import 'content/banner';
@import 'content/gallery';
@import 'content/item-actions';
@import 'content/miscellaneous';
@import 'content/quote';
@import 'content/search';
@import 'content/survey';
@import 'content/store';

/* Events */
@import 'content/events/author';
@import 'content/events/list';
@import 'content/events/reader';

/* News */
@import 'content/news/list';
@import 'content/news/meta';
@import 'content/news/reader';

/* Pages */
@import 'pages/homepage';

/* Import the fonts */
@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,700|Muli:300,400,600,600i,700,700i,800,800i,900');
