.ad_zone {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;

    > * {
        position: relative;
        margin-bottom: $vr !important;
        max-width: 100%;

        &:before {
            content: 'Werbung';
            display: block;
            width: 100%;
            position: absolute;
            left: 0;
            top: -20px;
            color: #a9a9a9;
            font-size: 11px;
            line-height: 16px;
            border-bottom: 1px solid #dddde1;
        }

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: #dddde1;
            position: absolute;
            left: 0;
            bottom: -4px;
        }
    }
}
