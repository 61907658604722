.ce_quote {
    padding-left: rem(44px);
    padding-right: rem(44px);

    color: #159265;
    line-height: 1;
    font-family: $font-family-alt;
    font-size: rem(24px);
    font-style: italic;
    font-weight: 800;
    text-align: center;

    @include media-breakpoint-up(lg) {
        font-size: rem(36px);
        font-weight: 700;
    }

    blockquote {
        &:before {
            content: '“';
        }

        &:after {
            content: '”';
        }
    }
}
