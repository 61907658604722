/* Typography */
html {
    font-size: $font-size;
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: rem(20px);
    font-family: $font-family-alt;
    font-weight: 600;
    line-height: 1;
}

h1 {
    margin-top: rem(20px);
    margin-bottom: rem(23px);

    @include media-breakpoint-up(lg) {
        margin-top: rem(40px);
        margin-bottom: rem(46px);
        font-size: rem(40px);
        line-height: 1.1;
        text-align: center;
    }
}

h2 {
    font-weight: 700;
    margin-bottom: 12px;

    @include media-breakpoint-up(lg) {
        line-height: 1.2;
    }
}

h3 {
    margin-bottom: 12px;
    font-weight: 300;
}

h5 {
    margin-bottom: 5px;
    font-weight: 700;
}

h6 {
    margin-bottom: 10px;
    font-weight: 900;
}

p {
    margin-bottom: rem(20px);
}

strong {
    font-weight: 700;
}
