.gallery {
    padding-top: rem(13px);
    padding-bottom: rem(6px);
    border-top: 2px solid $secondary;
    border-bottom: 2px solid $secondary;

    ul, li {
        @extend %reset;
    }

    figure {
        margin-bottom: 0;
    }

    .image_container img {
        width: auto;
    }

    &__link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0;
        padding-bottom: 56.25%;
        background: #000;
        text-align: center;

        img {
            position: absolute;
            top: 0;
            height: 100%;
        }

        &:after {
            content: '';
            display: block;
            width: 36px;
            height: 36px;
            position: absolute;
            right: 10px;
            bottom: 10px;
            background: url('../../images/zoom.svg') no-repeat center center;
            background-size: contain;
            pointer-events: none;
        }
    }

    &__caption {
        display: flex;
        align-items: flex-start;
        margin-top: rem(20px);
        color: #59595a;
    }

    &__counter {
        display: flex;
        align-items: center;
        margin-right: rem(10px);
        margin-top: rem(11px);
        font-size: rem(12px);
        font-weight: 700;
        line-height: 1;

        &-divider {
            font-weight: 300;
            font-size: rem(24px);
        }
    }

    &__description {
        flex-grow: 1;
    }

    &__label {
        color: #389583;
        line-height: 1;
        font-family: $font-family-alt;
        font-size: rem(11px);
        font-weight: 800;
        letter-spacing: .03rem;
        text-transform: uppercase;
    }

    &__title {
        font-weight: 700;
    }

    &__text {
        color: #59595a;
        font-size: rem(13px);
    }
}

.tns-outer {
    position: relative;

    .tns-controls button {
        position: absolute;
        top: calc(40% - 16.5px);
        width: 33px;
        height: 33px;
        display: block;
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 13px 23px;
        text-indent: -9999px;
        border: none;
        border-radius: 0;
        cursor: pointer;
        z-index: 1;
        -webkit-appearance: none;

        @include media-breakpoint-up(md) {
            top: 40%;
        }

        &[data-controls="prev"] {
            left: 0;
            background-image: url('../../images/slider-left.svg');
        }

        &[data-controls="next"] {
            right: 0;
            background-image: url('../../images/slider-right.svg');
        }
    }
}

#baguetteBox-overlay .full-image figcaption {
    padding: 1rem;
}
