$this: news-meta;

.#{$this} {
    display: flex;
    align-items: center;

    &--list {
        .#{$this}__content {
            font-size: .725rem;
            line-height: 1.1;
        }

        .#{$this}__image {
            margin-right: 7px;
            width: 27px;
        }
    }

    &--reader {
        margin-bottom: rem(20px);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem(50px);
            text-align: center;
            flex-direction: column;
        }

        .#{$this}__content {
            font-size: rem(13px);
            line-height: 1.23;
        }

        .#{$this}__image {
            margin-right: 8px;
            width: 50px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 8px;
                margin-right: 0;
                width: 90px;
            }
        }
    }

    &--list-opinion,
    &--reader-opinion {
        margin-bottom: rem(8px);
        flex-direction: column;
        text-align: center;

        .#{$this}__content {
            font-size: rem(13px);
            line-height: 1.23;
        }

        .#{$this}__image {
            margin-bottom: 8px;
            width: 90px;
        }
    }

    &--reader-opinion {
        @include media-breakpoint-up(lg) {
            margin-bottom: rem(20px);
        }
    }

    &--list-opinion .#{$this}__content {
        font-size: .725rem;
    }

    &--list-opinion-sub {
        display: none;
    }

    &__content {
        font-family: $font-family-alt;
    }

    &__subject {
        letter-spacing: .03rem;
        font-family: $font-family-alt;
        font-size: rem(11px);
        font-weight: 800;
        text-transform: uppercase;
        color: #a9a9a9;
    }

    &__author {
        font-weight: 700;
        color: #000000;

        > a {
            color: $link-color;

            &:hover {
                color: darken($link-color, 20%);
            }
        }
    }

    &__image {
        margin: 0;

        img {
            border-radius: 50%;
        }
    }
}
