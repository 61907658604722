.sub-navigation {
    @extend .container;
    margin: rem(-20px) ($grid-gutter-width / -2) rem(10px) ($grid-gutter-width / -2);
    font-family: $font-family-alt;
    font-size: .85rem;
    line-height: 1;

    @include media-breakpoint-up(sm) {
        margin-left: auto;
        margin-right: auto;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-bottom: rem(20px);
        font-size: rem(16px);
    }

    ul {
        @extend %reset;
        padding-top: rem(20px);
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        @include media-breakpoint-up(lg) {
            padding-top: 0;
            justify-content: center;
            white-space: normal;
        }

        &:after {
            content: "";
            display: block;
            height: 1px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 1px;
            background: #f0f0f0;
        }
    }

    li {
        position: relative;
        margin: 0;
        padding: 0;
        z-index: 1;

        &:not(:last-child) {
            margin-right: 12px;


            @include media-breakpoint-up(lg) {
                margin-right: 24px;
            }
        }
    }

    a, strong {
        display: block;
        padding: 4px 8px;
        border-bottom: 1px solid transparent;

        @include media-breakpoint-up(lg) {
            padding: 10px 7px;
            border-bottom-width: 2px;
        }
    }

    a {
        color: #a9a9a9;
        text-align: center;

        @include media-breakpoint-up(lg) {
            color: $body-color;
        }

        &:hover {
            color: #05396b;
        }
    }

    a.trail,
    strong {
        border-bottom-color: #05396b;
        color: #05396b;
        font-weight: 400;

        @include media-breakpoint-up(lg) {
            border-bottom-color: $secondary;
            color: $body-color;
        }
    }
}
