$icomoon-font-family: "icons" !default;
$icomoon-font-path: "fonts" !default;

$icon-external: "\e90f";
$icon-agenda: "\e900";
$icon-arrow-down: "\e901";
$icon-arrow-up: "\e902";
$icon-back: "\e903";
$icon-comments-fill: "\e904";
$icon-comments: "\e905";
$icon-email: "\e906";
$icon-facebook: "\e907";
$icon-heart-fill: "\e908";
$icon-heart: "\e909";
$icon-news: "\e90a";
$icon-search: "\e90b";
$icon-social: "\e90c";
$icon-twitter: "\e90d";
$icon-whatsapp: "\e90e";
$icon-stores: "\e910";
$icon-municipals: "\e911";

