.search {
    @include media-breakpoint-up(lg) {
        &__form {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__keywords {
        margin-bottom: rem(22px);
        display: flex;
        align-items: stretch;
        border-bottom: 2px solid $body-color;

        @include media-breakpoint-up(lg) {
            width: 100%;
        }

        input[type="text"] {
            height: 100%;
            padding: 0 5px;
            background: none;
            border: none;
            border-radius: 0;
            font-family: $font-family-base;
            font-size: rem(16px);
            font-weight: 300;

            &:focus {
                background: none;
                box-shadow: none;
            }

            &::-webkit-input-placeholder {
                color: #bfbfbf;
                opacity: 1;
            }
            &::-moz-placeholder {
                color: #bfbfbf;
                opacity: 1;
            }
            &:-ms-input-placeholder {
                color: #bfbfbf;
                opacity: 1;
            }
            &:-moz-placeholder {
                color: #bfbfbf;
                opacity: 1;
            }
        }

        .widget {
            width: 100%;
        }

        &-button {
            margin: 0;
            padding: 2px 6px;
            font-size: 24px;
            background: none;
            border: none;
            border-radius: 0;
            cursor: pointer;
        }
    }

    &__filters {
        max-width: 300px;
        margin: 0 auto;
        padding: rem(20px);
        background: #f0efef;

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 100%;
        }

        &-dates {
            margin-bottom: rem(16px);
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include media-breakpoint-up(lg) {
                margin-right: 18px;
                margin-bottom: 0;
            }

            .widget {
                display: flex;
                align-items: center;

                @include media-breakpoint-up(lg) {
                    &:first-child {
                        margin-right: 18px;
                    }
                }

                label {
                    margin-bottom: 0;
                    margin-right: 9px;
                    font-size: rem(14px);
                    font-weight: 400;
                }

                input {
                    max-width: 94px;
                    padding-top: 0;
                    padding-bottom: 0;
                    height: 30px;
                    line-height: 30px;
                    font-size: rem(12px);

                    &::-webkit-input-placeholder {
                        color: #bfbfbf;
                        font-style: italic;
                        opacity: 1;
                    }
                    &::-moz-placeholder {
                        color: #bfbfbf;
                        font-style: italic;
                        opacity: 1;
                    }
                    &:-ms-input-placeholder {
                        color: #bfbfbf;
                        font-style: italic;
                        opacity: 1;
                    }
                    &:-moz-placeholder {
                        color: #bfbfbf;
                        font-style: italic;
                        opacity: 1;
                    }
                }
            }
        }

        &-section {
            margin-bottom: rem(16px);

            @include media-breakpoint-up(lg) {
                margin-right: 18px;
                margin-bottom: 0;
                min-width: 222px;
            }
        }

        &-button-wrap {
            display: flex;
            justify-content: center;
        }

        &-button {
            min-width: 130px;
            padding: rem(6px) rem(8px);
        }
    }

    &__results {
        margin-top: rem(25px);
    }

    &__info {
        margin-bottom: rem(25px);
        color: #9e9e9d;
        font-size: rem(13px);
        font-weight: 300;
        text-align: center;
    }

    &__pagination {
        display: flex;
        justify-content: center;
        margin-top: rem(25px);

        .pagination {
            margin-bottom: 0;
        }
    }
}

$this: 'search-result';

.#{$this} {
    margin-bottom: rem(12px);

    @include media-breakpoint-up(lg) {
        margin-bottom: rem(28px);

        .#{$this}__image {
            display: block;
        }

        .#{$this}__thumbnail {
            display: none;
        }
    }

    &__main {
        display: block;
        margin-bottom: 9px;
        color: $body-color;
        pointer-events: auto;

        &--image {
            display: flex;

            .#{$this}__content {
                width: 62%;

                @include media-breakpoint-up(lg) {
                    width: 100%;
                }
            }
        }
    }

    &__headline {
        margin-bottom: 0;
        font-weight: 600;

        @include media-breakpoint-up(lg) {
            margin-bottom: 3px;
        }

        &--opinion {
            font-style: italic;
        }

        &-text {
            color: $body-color;

            &:hover {
                color: $secondary;
            }
        }
    }

    &__image {
        display: none;
        margin-bottom: 10px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 12px;
        }

        &-link {
            display: block;
        }

        img {
            width: 100%;
        }
    }

    &__thumbnail {
        width: 40%;
        margin: 9px 0 0 0;
        padding-left: 20px;
    }

    &__info {
        margin-top: 5px;
        color: #898888;
        font-size: rem(13px);

        &-section {
            font-style: italic;
        }
    }
}
