/* Form */
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
    @extend .form-control;

    &.error {
        @extend .is-invalid;
    }
}



.formbody {
    .widget {
        margin-bottom: $vr / 2;

        > label,
        > fieldset > legend {
            display: block;
            font-weight: bold;
            margin-bottom: .2rem;

            span.mandatory {
                margin-left: 2px;
                color: $danger;
            }
        }
    }

    p.error {
        margin-bottom: .2rem;
        color: $danger;
        font-weight: bold;
    }

    .radio_container,
    .checkbox_container {
        margin: 0;
        padding: 0;
        border: none;

        > span {
            display: block;
        }
    }

    .captcha {
        width: 75px;
        text-align: center;
        margin-right: 8px;
    }

    .captcha_text {
        font-weight: bold;
    }

    .submit {
        @extend .btn;
        @extend .btn-primary;
    }
}

.custom-select-wrapper {
    position: relative;
    height: 30px;
    line-height: 30px;
    padding: 0 8px;
    font-size: rem(14px);
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    background: $input-bg;

    &:before {
        display: block;
        position: absolute;
        top: calc(50% - 3px);
        right: 10px;
        font-size: 6px;
        pointer-events: none;

        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icons' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        content: $icon-arrow-down;
    }

    &-value {
        pointer-events: none;
    }

    select {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100% !important;
        opacity: 0;
    }
}

/* Ajax confirmation */
form .ajaxconfirm {
    @extend .alert;
    @extend .alert-success;

    *:last-child {
        margin-bottom: 0;
    }
}
