$this: item-actions;

.#{$this} {
    display: flex;
    position: relative;

    &--list {
        .#{$this}__action {
            &:not(:last-child) {
                margin-right: 15px;
            }

            &--comments,
            &--likes,
            &--social-trigger {
                i {
                    font-size: rem(18px);
                }
            }

            &--likes i.icon-heart-fill {
                display: none;
            }

            &--likes.active {
                i.icon-heart {
                    display: none;
                }

                i.icon-heart-fill {
                    display: inline;
                }
            }
        }

        .#{$this}__counter {
            font-size: .725rem;
        }
    }

    &--reader {
        position: fixed;
        left: 50%;
        bottom: 59px;
        transform: translateX(-50%);
        z-index: 10;

        justify-content: center;
        align-items: center;
        height: 43px;
        padding: 0 17px;
        border-radius: 19px;
        background-color: #fff;
        box-shadow: 0 0 10px 1px #c0c0c0;

        @include media-breakpoint-up(lg) {
            position: static;
            margin-bottom: rem(34px);
            box-shadow: none;
            transform: none;
        }

        @include media-breakpoint-down(md) {
            body.menu-active & {
                display: none;
            }
        }

        .#{$this}__action {
            &:not(:last-child) {
                margin-right: 20px;
            }

            &--comments,
            &--likes,
            &--social-trigger {
                i {
                    font-size: rem(21px);
                }
            }

            &--likes {
                &.active {
                    color: #f97d7d;
                }

                i.icon-heart {
                    display: none;
                }
            }
        }

        .#{$this}__counter {
            font-size: rem(18px);
        }
    }

    &--standalone {
        margin-bottom: rem(50px);
        justify-content: center;
        align-items: center;
        height: 43px;

        .#{$this}__action {
            &:not(:last-child) {
                margin-right: 12px;
            }

            &--social-trigger i {
                font-size: rem(21px);
            }
        }
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: center;

        &--social {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            color: #fff;

            &:hover {
                color: #fff;
            }

            &:not(:last-child) {
                margin-right: 12px;
            }

            i {
                font-size: 16px;
            }
        }

        &--facebook {
            background-color: #405992;
        }

        &--twitter {
            background-color: #2fa9e0;

            i {
                font-size: 12px;
            }
        }

        &--whatsapp {
            background-color: #25d366;
        }

        &--email {
            background-color: #c5c4c4;

            i {
                font-size: 10px;
            }
        }
    }

    &__counter {
        margin-left: 4px;
        font-family: $font-family-alt;
        font-weight: 700;
    }

    &__popup {
        position: absolute;
        bottom: calc(100% + 10px);
        padding-bottom: 10px;
        margin-left: 9px;
        opacity: 0;
        pointer-events: none;
        transition: opacity .2s;

        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: calc(50% - 7px);
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 10px solid #eee;
        }

        &.popup-active {
            opacity: 1;
            pointer-events: auto;
        }

        &-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 43px;
            padding: 0 17px;
            border: 1px solid #eee;
            border-radius: 19px;
            background-color: #fff;
            box-shadow: 0 0 5px 1px #eee;
        }
    }
}
