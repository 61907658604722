
.store-list {
    @include item-listing();

    &-item {
        display: flex;
        margin-bottom: rem(12px);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem(28px);
        }

        &__image {
            width: 30%;
        }

        &__main {
            width: 70%;
            padding-left: 20px;
            margin-bottom: 9px;
            color: $body-color;
        }

        &__headline {
            margin-bottom: 0;
            font-weight: 600;

            @include media-breakpoint-up(lg) {
                margin-bottom: 3px;
            }
        }

        &__description {
            margin-bottom: 1px;
            font-family: $font-family-alt;
            font-size: rem(11px);
            font-weight: 800;
            letter-spacing: .03rem;
            text-transform: uppercase;
            color: #159265;
        }

        &__company-url {
            display: inline-block;
            width: 60px;
            height: 21px;
            margin-top: .5em;
            background: url("../../images/store-website.svg") 0 0 no-repeat;
            text-indent: -999em;
        }

        &__shopping-url {
            display: inline-block;
            width: 100px;
            height: 22px;
            margin-top: .5em;
            background: url("../../images/store-shopping.svg") 0 0 no-repeat;
            text-indent: -999em;
        }
    }
}
