body.news-reader {
    .header__back {
        visibility: visible;
        pointer-events: auto;
    }

    @include media-breakpoint-down(md) {
        .footer__content {
            padding-bottom: 68px; // Make space for actions bar
        }
    }
}

.mod_news_reader {
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
        padding-top: 8px;
    }
}

.news-reader-comments {
    margin-top: rem(40px);
    padding: rem(40px) 0;
    background: #eaeff4;
    border-top: 3px solid #043260;
}

$this: news-reader-item;

.#{$this} {
    &--opinion {
        .#{$this}__headline {
            margin-bottom: rem(18px);
            text-align: center;
            font-style: italic;
        }

        .#{$this}__section {
            margin-bottom: rem(8px);
            display: flex;
            justify-content: center;
            font-family: $font-family-alt;
            font-size: rem(11px);
            font-weight: 800;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                margin-bottom: rem(15px);
                text-align: center;
                font-size: rem(16px);
                font-weight: 700;
            }

            &-link {
                color: #159265;
            }
        }


        .#{$this}__section {
            color: #a9a9a9;

            i {
                margin: 0 10px;
                font-style: normal;
            }
        }
    }

    &__official {
        margin-top: rem(10px);
        margin-bottom: rem(21px);

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: center;
            margin-bottom: rem(40px);
        }

        &-content {
            min-height: 54px;
            padding-left: 54px;
            line-height: 1;
            background: url('../../../images/news-official.png') no-repeat left center;
            font-family: $font-family-alt;
        }

        &-name {
            margin-bottom: 4px;
            padding-top: 3px;
            color: #37ba85;
            font-size: rem(24px);
            font-weight: 700;
        }

        &-label {
            font-size: rem(20px);
            font-weight: 400;
        }
    }

    &__headline {
        margin-top: 0;
        margin-bottom: rem(11px);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem(28px);
            text-align: center;
        }
    }

    &__subheadline {
        margin-bottom: rem(10px);
        font-family: $font-family-alt;
        font-size: rem(20px);
        font-weight: 400;

        @include media-breakpoint-up(lg) {
            margin-bottom: rem(25px);
            text-align: center;
        }
    }

    &__image {
        margin-bottom: rem(30px);

        &-caption {
            margin-top: 5px;
            color: #59595a;
            font-size: rem(13px);
            font-style: italic;
        }

        img {
            width: 100%;
        }
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: rem(22px);
    }

    &__tag {
        display: block;
        margin: 0 11px 11px 0;
        padding: 8px 15px;
        color: $body-color;
        font-family: $font-family-alt;
        font-size: rem(13px);
        line-height: 1;
        background: #f0efef;
    }

    &__related {
        padding-top: 70px;

        @include media-breakpoint-up(lg) {
            padding-top: 0;
        }

        &:before {
            content: '';
            display: block;
            width: 100vw;
            height: 40px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: #e7e7e7;
            pointer-events: none;

            @include media-breakpoint-up(sm) {
                left: calc((100vw - 540px) / -2);
            }

            @include media-breakpoint-up(md) {
                left: calc((100vw - 720px) / -2);
            }

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }
}
